import React, { useState } from 'react';
import queryString from 'query-string';
import MD5 from 'md5';

import api from '../../services/Api';

import './style.css';
import logo from '../../assets/images/logo.png';

export default function RecuperarSenha(props) {
    const querystring = queryString.parse(props.location.search);

    const [novaSenha, setNovaSenha] = useState(null);
    const [confirmaNovaSenha, setConfirmaNovaSenha] = useState(null);
    
    function alterarSenha(){
        if (novaSenha.length < 6) {
            alert('Sua senha deve ter ao menos 6 caracteres');
        }
        else if (!!novaSenha === false){
            alert('Você deve informar sua nova senha!');
        }
        else if (novaSenha !== confirmaNovaSenha){
            alert('As senhas não conferem. Verifique!');
        }
        else {
            var obj = {};
            obj.novaSenha = MD5(novaSenha);
            api.post('/usuarioapp/alterarSenha/' + querystring.hash, JSON.stringify(obj)).then((dados)=>{
                if (dados.status === 200){
                    alert('Sua senha foi alterada com sucesso!');
                    window.location = 'https://www.cineglobocinemas.com.br';
                }
            });
        }
    }

  return (
    <div className="mainContent">
        <div className="preHeaderText">
            Esqueceu sua senha de acesso ao aplicativo?
        </div>

        <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <td bgcolor="#7E1214" align="center">
                <table border="0" cellpadding="0" cellspacing="0" width="480" >
                    <tr>
                        <td align="center" valign="top" style={{padding: '40px 10px 40px 10px'}}>
                            <a href="http://litmus.com" target="_blank">
                                <img alt="Logo" src={logo} width="200" height="60" style={{display: "block", fontFamily: "'Lato', Helvetica, Arial, sans-serif", color: "#ffffff", fontSize: "18px", border: "0" }} />
                            </a>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr>
            <td bgcolor="#7E1214" align="center" style={{padding: "0px 10px 0px 10px"}}>
                <table border="0" cellpadding="0" cellspacing="0" width="480" >
                    <tr>
                        <td bgcolor="#ffffff" align="center" valign="top" style={{padding: "40px 20px 20px 20px", borderRadius: "4px 4px 0px 0px", color: "#111111", fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: "48px", fontWeight: "400", letterSpacing: "4px", lineHeight: "48px"}}>
                            <h1 style={{fontSize: "32px", fontWeight: "400", margin: "0" }}>Esqueceu sua Senha de acesso ao nosso aplicativo?</h1>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr>
            <td bgcolor="#f4f4f4" align="center" style={{padding: "0px 10px 0px 10px"}}>
                <table border="0" cellpadding="0" cellspacing="0" width="480" >
                
                <tr>
                    <td bgcolor="#ffffff" align="left" style={{padding: "20px 30px 40px 30px", color: "#666666", fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: "18px", fontWeight: "400", lineHeight: "25px"}}>
                        <p style={{margin: 0}}>Não se preocupe, basta criar uma nova senha para sua conta no formulário abaixo:</p>
                    </td>
                </tr>

                <tr>
                    <td bgcolor="#ffffff" align="left" style={{padding: "20px 30px 40px 30px", color: "#666666", fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: "18px", fontWeight: "400", lineHeight: "25px"}}>
                        <input className="inputText" onChange={(e) => setNovaSenha(e.target.value)} type="password" placeholder="Informe sua nova senha" />
                        <input className="inputText" onChange={(e) => setConfirmaNovaSenha(e.target.value)} type="password" placeholder="Repita aqui a sua nova senha" />
                    </td>
                </tr>

                
                <tr>
                    <td bgcolor="#ffffff" align="left">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                        <td bgcolor="#ffffff" align="center" style={{padding: "20px 30px 60px 30px"}}>
                            <table border="0" cellspacing="0" cellpadding="0">
                            <tr>
                                <td align="center" style={{borderRadius: "3px"}} bgcolor="#7E1214">
                                    <a onClick={()=>alterarSenha()} style={{ cursor: 'pointer', fontSize: "20px", fontFamily: "Helvetica, Arial, sans-serif", color: "#ffffff", textDecoration: "none", color: "#ffffff", textDecoration: "none", padding: "15px 25px", borderRadius: "2px", border: "1px solid #000000", display: "inline-block"}}>Criar nova senha</a>
                                </td>
                            </tr>
                            </table>
                        </td>
                        </tr>
                    </table>
                    </td>
                </tr>
                </table>
            </td>
        </tr>
        
        <tr>
            <td bgcolor="#f4f4f4" align="center" style={{padding: "0px 10px 0px 10px"}}>
                <table border="0" cellpadding="0" cellspacing="0" width="480" >
                
                <tr>
                    <td bgcolor="#f4f4f4" align="left" style={{padding: "0px 30px 30px 30px", color: "#666666", fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: "14px", fontWeight: "400", lineHeight: "18px"}} >
                    <p style={{margin: 0}}>&copy; Cineglobo Cinemas {new Date().getFullYear()}.</p>
                    </td>
                </tr>
                </table>
            </td>
        </tr>
    </table>


    </div>
  );
}
