import React, {useState} from 'react';

import './App.css';

function App() {
  return (
    <div>CINEGLOBO CINEMAS</div>
  )
}

export default App;
