import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom'

import './index.css';
import App from './App';
import SelecionarPoltrona from './pages/selecionar-poltrona';
import RecuperarSenha from './pages/recuperar-senha';

ReactDOM.render(
    
        <HashRouter>
            <Route exact path="/" exact={true} component={App} />
            <Route exact path="/selecionar-poltrona" component={SelecionarPoltrona} />
            <Route exact path="/recuperar-senha" component={RecuperarSenha} />
        </HashRouter>
   
    , document.getElementById('root'));


