import React, { useState, useEffect } from 'react';
import SeatPicker from 'react-seat-picker';
import queryString from 'query-string';

import api from '../../services/Api';

import './style.css';

export default function SelecionarPoltrona(props) {

  const fileiras = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ];

  const querystring = queryString.parse(props.location.search);

    /*const [ rows, setRows ] = useState([
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3 CAD', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6 OBE', isReserved: false }, null, null, null, { id:7, number: '7 PNE', isReserved: false }, { id:8, number: '8', isReserved: false }, { id:9, number: '9 CAD', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, null ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, { id:8, number: '8', isReserved: false }, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, { id:15, number: '15', isReserved: false } ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, null, null, { id:9, number: '9', isReserved: false }, { id:10, number: '10', isReserved: false }, { id:11, number: '11', isReserved: false }, { id:12, number: '12', isReserved: false }, { id:13, number: '13', isReserved: false }, { id:14, number: '14', isReserved: false }, null ],
        [{ id:1, number: '1', isReserved: false }, { id:2, number: '2', isReserved: false }, { id:3, number: '3', isReserved: false }, { id:4, number: '4', isReserved: false }, { id:5, number: '5', isReserved: false }, { id:6, number: '6', isReserved: false }, { id:7, number: '7', isReserved: false }, ],
      ]);*/

      const [ rows, setRows ] = useState([]);
      const [ poltronasSelecionadas, setPoltronasSelecionadas ] = useState([]);

      const [ loading, setLoading ] = useState(false);

      useEffect(() => {
        setLoading(true);
        api.get('exibicao/retornamapapoltronas/' + querystring.exibicao).then((dados) => {
          setRows(dados.data);
          setLoading(false);
        });
      }, []);

      async function reservarPoltrona(linha, numeroPoltrona) {
        try {
          var retornoReserva = await api.post('exibicao/reservarpoltrona', JSON.stringify({ exibicao_id: querystring.exibicao, row: linha, poltrona: numeroPoltrona }));
          return true;
        }
        catch(error) {
          if (error.status === 409) {
            alert('Esta poltrona foi selecionada segundos atrás por outro cliente! Selecione outra...');
          }
          return false;
        }
      }

      async function liberarPoltrona(linha, numeroPoltrona) {
        try {
          var retornoReserva = await api.post('exibicao/liberarpoltrona', JSON.stringify({ exibicao_id: querystring.exibicao, row: linha, poltrona: numeroPoltrona }));
          return true;
        }
        catch(error) {
          if (error.status === 409) {
            alert('Erro ao acessar mapa de poltronas');
          }
          return false;
        }
      }
    
      async function addSeatCallback(row, number, id, cb){
        setLoading(true);
        //console.log(`Added seat ${number}, row ${row}, id ${id}`);
        var reservado = await reservarPoltrona(row, (id));
        if (reservado) {
          cb(row,number)
          let listaPoltronas = poltronasSelecionadas;
          listaPoltronas.push(row+''+number);
          setPoltronasSelecionadas(listaPoltronas);
          console.log(JSON.stringify(listaPoltronas));
          if (querystring.acesso == 'APP') window.ReactNativeWebView.postMessage(JSON.stringify(listaPoltronas));

          setLoading(false);
        }
        else {
          setLoading(false);
        }
      }

      async function removeSeatCallback(row, number, id, cb){
        setLoading(true);
        //console.log(`Removeu selecao ${number}, row ${row}, id ${id}`);
        var liberado = await liberarPoltrona(row, (id));
          cb(row,number);

          let listaPoltronasSelecionadas = poltronasSelecionadas;

          let indice = listaPoltronasSelecionadas.findIndex((element, index, array) => {
            return element == row+''+number;
          });

          listaPoltronasSelecionadas.splice(indice, 1);

          console.log(JSON.stringify(listaPoltronasSelecionadas));

          setPoltronasSelecionadas(listaPoltronasSelecionadas);

          if (querystring.acesso == 'APP') window.ReactNativeWebView.postMessage(JSON.stringify(listaPoltronasSelecionadas));
          if (querystring.acesso == 'SIS') window.retornoPoltronas = listaPoltronasSelecionadas;
        
          setLoading(false);
      }
    
      return (
        <div>
            <div className="legenda">
              <h1>Legenda de poltronas especiais</h1>
              <span><b>PNE</b> = Portador de Necessidades Especiais</span>
              <span><b>CAD</b> = Poltrona Reservada à Cadeirantes</span>
              <span><b>OBE</b> = Poltrona Reservada à Obesos</span>
            </div>
            <div className="tela">
              # TELA #
            </div>
            <div style={{width:'100%', overflow: 'scroll'}}>
              <div style={{maxHeight:'100%', overflow: 'auto', width: '1600px'}}>
              <SeatPicker
                addSeatCallback={addSeatCallback}
                removeSeatCallback={removeSeatCallback}
                rows={rows}
                maxReservableSeats={(parseInt(querystring.qtde) || 1)}
                alpha
                visible
                loading={loading}
              />
            </div></div>
          </div>
      );
}
