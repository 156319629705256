import { create } from 'apisauce';

const api = create({
	baseURL: 'https://www.cineglobocinemas.com.br/api/',
});

api.addResponseTransform(response => {
	if (!response.ok) throw response;
});

export default api;